section#user{
    /*background-color: #1f7fe2;*/
    /*min-height: 50px;*/
}
.bg-primary a{
    color: white;
}
@media screen and (min-width: 992px) {
    #banner{
        background-image: url("html_file/banner1.jpg");
        height: 500px;
        background-size: cover;
        background-position: center;
        min-width: 100%;
    }
}
.bg-bludepuracqua{
    background-color: $bludepuracqua;
}
.bg-bludepuracqua a{
    color: $white !important;
}
#ulNav{
    margin: 0px;
}
#app > .container{
    margin-top: 20px;
    margin-bottom: 20px;
}
